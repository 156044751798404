
import _ from 'lodash';
const HostnameCollection = [
    'localhost',
    'predevsl.vinfotechprojects.com',
    'sloptimise.vinfotechprojects.com',
    'fantasy-stg.indiansuperleague.com',
]
const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;
console.log(hostname,"hostnamehostname")


const dev = {
    s3: {
        BUCKET: "https:\/\/sl-dev-vtech.s3.amazonaws.com/",
    },
    apiGateway: {
        URL: 'https://fantasy-stg.indiansuperleague.com/',
        // URL: 'http://192.168.5.45/slframework/', 
        
    }
};

const prod = {
    s3: {
        BUCKET: 'https:\/\/slprd_vtech.s3.amazonaws.com/',
    },
    apiGateway: {
        URL: ' https://sl.vinfotechprojects.com/',
    }
};

const config = _.includes(HostnameCollection, hostname) || hostname.match(regex)
    ? dev
    : prod;

export default {
    ...config
};


